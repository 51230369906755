import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import { Freya } from '../src/components/Freya/Freya';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Loki from '../src/components/Loki/lokipane';
import { MagniLeft } from '../src/components/Magni/MagniLeft';
import { MagniRight } from '../src/components/Magni/MagniRight';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { Saga } from '../src/components/Saga/Saga';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Hod from '../src/components/Hod/Hod';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import Layout from '../src/components/Layout/Layout';
 
import './ficha_tiendas.scss';

export const GatsbyQuery = ({ data, pathContext: { restaurant_name } }) => graphql`
  query($restaurant_name: String!, $locale: String!) {
    allRestaurantDetailsHeaderBlock(
      filter: { tag: { eq: $restaurant_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          title
          description
        }
      }
    }
    allRestaurantDetailsSeoData(filter: { tag: { eq: $restaurant_name }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allRestaurantDetailsImageHeaderBlock(
      filter: { tag: { eq: $restaurant_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image
        }
      }
    }
    allRestaurantDetailsSagaBlock(
      filter: { tag: { eq: $restaurant_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          image
          image_alt
        }
      }
    }
    allRestaurantDetailsTextSagaBlock(
      filter: { tag: { eq: $restaurant_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          title
          summary
          summary2
          ctaText1
          cta1
          body
        }
      }
    }
    allRestaurantDetailsLokiImages(
      filter: { tag: { eq: $restaurant_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    bigLokiPics: allRestaurantDetailsLokiImages(
      filter: { tag: { eq: $restaurant_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allRestaurantDetailsMagniBlock(
      filter: { tag: { eq: $restaurant_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          left_link_copy
          left_link_path
          right_link_copy
          right_link_path
        }
      }
    }
    allRestaurantDetailsSchedule(filter: { tag: { eq: $restaurant_name }, lang: { eq: $locale } }) {
      edges {
        node {
          body
        }
      }
    }
    allRestaurantsMosaicThreeBlock(
      filter: { tag: { eq: $restaurant_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          tag
          title
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allRestaurantDetailsBreadCrumbBlock(
      filter: { tag: { eq: $restaurant_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allRestaurantsDetailsSeoRichData(
      filter: { tag: { eq: $restaurant_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
            logo_url
            cuisine_type
            phone
            inside_place
            hotel_id
          }
        }
      }
    }
    allRestaurantDetailsPromotionalBlock(
      filter: { tag: { eq: $restaurant_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          tag
          call_to_action_copy
          call_to_action_path
          blank
          textBox
          cta2
          ctaText2
        }
      }
    }
  }
`;

class shopDetails extends Component {
  state = {};

  render() {
    let sagaData;
    const heimdallData = {
      name: this.props.data.allRestaurantDetailsHeaderBlock.edges[0].node.title,
      subtitle: this.props.data.allRestaurantDetailsHeaderBlock.edges[0].node.description,
      image: {
        url: this.props.data.allRestaurantDetailsImageHeaderBlock.edges[0].node.localImage
          .childImageSharp.fluid,
      },
    };

    if (
      this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node.call_to_action_path ===
        '' &&
      this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node.cta2 === ''
    ) {
      sagaData = {
        image: this.props.data.allRestaurantDetailsSagaBlock.edges[0].node.image,
        altImage: this.props.data.allRestaurantDetailsSagaBlock.edges[0].node.image_alt,
        title: this.props.data.allRestaurantDetailsTextSagaBlock.edges[0].node.title,
        schudle: this.props.data.allRestaurantDetailsSchedule.edges[0].node.body,
        summary:
          this.props.data.allRestaurantDetailsTextSagaBlock.edges[0].node.summary +
          this.props.data.allRestaurantDetailsTextSagaBlock.edges[0].node.summary2,
        social: false,
        textBox: this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node.textBox,
      };
    } else if (
      this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node.call_to_action_path ===
        '' &&
      this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node.cta2 !== ''
    ) {
      sagaData = {
        image: this.props.data.allRestaurantDetailsSagaBlock.edges[0].node.image,
        altImage: this.props.data.allRestaurantDetailsSagaBlock.edges[0].node.image_alt,
        title: this.props.data.allRestaurantDetailsTextSagaBlock.edges[0].node.title,
        textBox: this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node.textBox,
        summary:
          this.props.data.allRestaurantDetailsTextSagaBlock.edges[0].node.summary +
          this.props.data.allRestaurantDetailsTextSagaBlock.edges[0].node.summary2,
        social: false,
        schudle: this.props.data.allRestaurantDetailsSchedule.edges[0].node.body,

        buttonPDF: {
          cta: this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node.cta2,
          size: 'alone',
          color: 'orange',
          ctaText: this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node.ctaText2,
        },
      };
    } else if (
      this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node.call_to_action_path !==
        '' &&
      this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node.cta2 === ''
    ) {
      sagaData = {
        image: this.props.data.allRestaurantDetailsSagaBlock.edges[0].node.image,
        altImage: this.props.data.allRestaurantDetailsSagaBlock.edges[0].node.image_alt,
        title: this.props.data.allRestaurantDetailsTextSagaBlock.edges[0].node.title,
        textBox: this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node.textBox,
        summary:
          this.props.data.allRestaurantDetailsTextSagaBlock.edges[0].node.summary +
          this.props.data.allRestaurantDetailsTextSagaBlock.edges[0].node.summary2,
        social: false,
        schudle: this.props.data.allRestaurantDetailsSchedule.edges[0].node.body,
        button1: {
          cta: this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node
            .call_to_action_path,
          size: 'alone',
          blank: this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node.blank,
          color: 'orange',
          ctaText: this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node
            .call_to_action_copy,
        },
      };
    } else {
      sagaData = {
        image: this.props.data.allRestaurantDetailsSagaBlock.edges[0].node.image,
        altImage: this.props.data.allRestaurantDetailsSagaBlock.edges[0].node.image_alt,
        title: this.props.data.allRestaurantDetailsTextSagaBlock.edges[0].node.title,
        textBox: this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node.textBox,
        summary:
          this.props.data.allRestaurantDetailsTextSagaBlock.edges[0].node.summary +
          this.props.data.allRestaurantDetailsTextSagaBlock.edges[0].node.summary2,
        social: false,
        schudle: this.props.data.allRestaurantDetailsSchedule.edges[0].node.body,
        button1: {
          cta: this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node
            .call_to_action_path,
          size: 'alone',
          blank: this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node.blank,
          color: 'orange',
          ctaText: this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node
            .call_to_action_copy,
        },
        buttonPDF: {
          cta: this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node.cta2,
          size: 'alone',
          color: 'orange',
          ctaText: this.props.data.allRestaurantDetailsPromotionalBlock.edges[0].node.ctaText2,
        },
      };
    }
    const PrepareforLoki = src => ({
      arrayImg: this.props.data.allRestaurantDetailsLokiImages.edges
        .map(e => ({
          img: e.node.localImage ? e.node.localImage.childImageSharp.fluid : undefined,
          altImage: e.node.image_alt,
        }))
        .filter(e => e.img !== undefined),
      bigArrayImg: this.props.data.bigLokiPics.edges
        .map(e => ({
          img: e.node.localImage ? e.node.localImage.childImageSharp.fluid : undefined,
          altImage: e.node.image_alt,
        }))
        .filter(e => e.img !== undefined),
    });

    const PrepareforFreya = src => ({
      cards: src.edges
        .map(e => ({
          image: e.node.localImage ? e.node.localImage.childImageSharp.fluid : undefined,
          altImage: e.node.image_alt,
          imgsize: 'promoted',
          title: e.node.title,
          buttons: e.node.buttons,
        }))
        .filter(e => e.image !== undefined),
    });
    return (
      <Layout layoutFromOld 
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allRestaurantDetailsSeoData.edges[0].node._0.title}
          description={this.props.data.allRestaurantDetailsSeoData.edges[0].node._1.description}
          // canonicalLink={`https://www.portaventuraworld.com${this.props.location.pathname}`}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allRestaurantsDetailsSeoRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allRestaurantDetailsImageHeaderBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="shop-details-content">
              <div className="top-shop-details">
                <NeoAesir
                  locale={this.props.pageContext.locale}
                  pageName={this.props.pageContext.pageName}
                  options={this.props.pageContext.options}
                  title={this.props.data.allRestaurantDetailsBreadCrumbBlock.edges[0].node.name}
                />
              </div>
              <div className="shop-arrows-content">
                <MagniLeft data={this.props.data.allRestaurantDetailsMagniBlock.edges[0].node} />
                <MagniRight data={this.props.data.allRestaurantDetailsMagniBlock.edges[0].node} />
              </div>
              <BlueArrow />
              <Saga data={sagaData} />
              {this.props.data.allRestaurantDetailsLokiImages &&
                this.props.pageContext.pageName.includes('dagana') && (
                  <H4 title={tt('GALERIA DE FOTOS', this.props.pageContext.locale)} />
                )}
              {this.props.data.allRestaurantDetailsLokiImages &&
                this.props.pageContext.pageName.includes('dagana') && (
                  <Loki data={PrepareforLoki()} />
                )}
              <div className="promotion-content">
                <H4 title={tt('EXPLORA OTROS RESTAURANTES', this.props.pageContext.locale)} />
                <Freya data={PrepareforFreya(this.props.data.allRestaurantsMosaicThreeBlock)} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
shopDetails.propTypes = {
  shopDetailsData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
};
/* eslint-enable react/require-default-props */

export default shopDetails;
